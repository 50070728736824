.hero-banner {
  padding: 119px 0 170px;
  position: relative;
  background-color: var(--black);
  text-align: center;
  overflow: hidden;
  @include tablet-small {
    padding: 60px 0;
  }
  &:before {
    content: "";
    background-color: #db2777;
    border-radius: 50%;
    opacity: 0.1;
    -webkit-backdrop-filter: blur(140px);
    -webkit-filter: blur(140px);
    filter: blur(140px);
    width: 682px;
    height: 600px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    @include mobile {
        height: 250px;
    }
  }
  &::after {
    content: "";
    background: #4f46e5;
    opacity: 0.2;
    -webkit-backdrop-filter: blur(140px);
    -webkit-filter: blur(140px);
    filter: blur(140px);
    width: 682px;
    height: 600px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    @include mobile {
        height: 250px;
        top: auto;
        bottom: 0;
    }
  }
  .banner-bg {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='127' height='196' viewBox='0 0 127 196' fill='none'%3E%3Cpath opacity='0.16' d='M111 64C111 47.9837 98.0163 35 82 35L82 64L111 64Z' fill='%23F0F0F5'/%3E%3Crect opacity='0.08' x='57' y='29' width='26' height='26' transform='rotate(-180 57 29)' stroke='%23F5F4FA' stroke-width='6'/%3E%3Crect opacity='0.08' x='127' y='80' width='16' height='16' transform='rotate(-180 127 80)' fill='%23F5F4FA'/%3E%3Cpath opacity='0.08' d='M50 106C50 127.539 67.4609 145 89 145L89 106L50 106Z' fill='%23F5F4FA'/%3E%3Cpath opacity='0.16' d='M0 56C27.6142 56 50 78.3858 50 106L-4.37114e-06 106L0 56Z' fill='%23F0F0F5'/%3E%3Cellipse opacity='0.08' cx='25' cy='126' rx='8' ry='8' transform='rotate(-180 25 126)' fill='%23F5F4FA'/%3E%3Cpath opacity='0.16' d='M53 183C53 177.477 57.4772 173 63 173C68.5228 173 73 177.477 73 183C73 188.523 68.5228 193 63 193C57.4772 193 53 188.523 53 183Z' stroke='%23F0F0F5' stroke-width='6'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 101px 168px;
      content: "";
      display: block;
      height: 168px;
      left: 0;
      position: absolute;
      top: 0;
      width: 101px;
    }
  }
  .btn {
    display: none;
  }
  h1,
  .btn {
    position: relative;
    z-index: 1;
  }
}

.banner-blocks {
  margin-top: -64px;
  position: relative;
  z-index: 99;
  h3 {
    a {
      font-size: 24px;
      line-height: 30px;
      color: var(--blue);
      margin-bottom: 16px;
      font-family: "Lexend", sans-serif;
      @include mobile {
          font-size: 22px;
          line-height: 25px;
      }
    }
    &:hover a {
      color: var(--black);
    }
  }
  .blocks-list {
    @include display-flex(flex);
    @include justify-content(center);
    margin: 0 -16px;
    padding: 0;
    @include mobile-small {
        @include flex-direction(column);
        margin: 0;
    }
  }
  .blocks-items {
    display: block;
    box-shadow: 0px 6px 10px -1px rgba(6, 25, 56, 0.07);
    border-radius: 8px;
    border: 1px solid #e5e5eb;
    max-width: 352px;
    margin: 0 16px 0;
    background-color: var(--white);
    @include tablet {
      width: calc(100% / 2 - 16px);
    }
    @include mobile-small {
        width: 100%;
        max-width: none;
        margin: 0;
        +.blocks-items {
            margin-top: 40px;
        }
    }
  }
  .block-content {
    padding: 32px;
    svg {
        margin-bottom: 16px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 0px;
        color: var(--Grey);
        line-height: 24px;
      }
  }
}

.theme-development {
    padding: 104px 0 0;
    text-align: center;
    @include mobile {
        padding: 50px 0 0;
    }
    p {
        margin: 0;
    }
}
.theme-content {
    padding: 48px 96px 120px 0;
    @include tablet {
        padding: 50px 40px 0;
    }
    @include tablet-small {
        padding: 30px 50px 0;
        flex-direction: column;
    }
    @include mobile {
        padding: 30px 0 0;
    }
    > div {
        width: 50%;
        @include tablet-small {
            width: 100%;
            margin: auto;
            text-align: center;
        }
        @include mobile-small {
            text-align: left;
        }
    }
    li {
        margin: 24px 0 0;
        border-bottom: 1px solid #E5E5EB;
        padding-bottom: 24px;
        @include tablet {
            margin: 30px 0;
        }
        p {
          margin-bottom: 0;
        }
    }
    img {
        max-width: 448px;
        margin: auto;
        @include mobile {
            max-width: none;
            width: 100%;
        }
    }
    h4 {
        a {
            &:hover {
                &::after {
                    display: inline-block;
                }
            }
            &:after {
                content: "";
                display: none;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6.05377 4.63956C6.95763 5.54342 6.95763 7.01658 6.05377 7.92044L1.70711 12.2671C1.31658 12.6576 0.683417 12.6576 0.292893 12.2671C-0.0976311 11.8766 -0.0976311 11.2434 0.292893 10.8529L4.63956 6.50623C4.76237 6.38342 4.76237 6.17658 4.63956 6.05377L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z' fill='%234F46E5'/%3E%3C/svg%3E") no-repeat;
                background-size: 100% 100%;
                width: 8px;
                height: 13px;
                margin-left: 14px;
            }
        }
    }
}

.book-demo-section {
  display: none;
}
// #mosaic-provider-react-aria-0-2 {
//   .sl-bg-transparent {
//     > div {
//       z-index: 9999;
//     }
//   }
// }