.common-mt-mb {
    margin: 50px 0px;
}
.container {
    padding: 0 20px;
}
.book-demo-section .main-book {
    flex-wrap: wrap;
    justify-content: space-between;
}
a.primary-button {
    background-color: var(--pink);
    align-items: center;
    padding: 10px 32px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--white);
    transition: .3s ease-in-out;
    margin-left: 78px;
}

a.primary-button:hover {
    background-color: var(--white);
    color: var(--pink);
}
.book-demo-section .button-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    @include tablet {
        margin: 20px auto 0;
    }
}

.book-demo-section .center-heading {
    margin: 0;
    color: var(--white);
}

.book-demo-section .button-box a {
    display: inline-block;
}

.book-demo-section .button-box a:hover {
    color: var(--pink);
}

.book-demo-section .main-book {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 49px 96px;
    background: var(--blue);
    border-radius: 8px;
    position: relative;
    @include tablet {
        text-align: center;
        padding: 40px 20px;
    }
    &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='112' height='104' viewBox='0 0 112 104' fill='none'%3E%3Cpath opacity='0.08' d='M32 21C14.3269 21 -5.06719e-06 35.3269 -6.61223e-06 53L32 53L32 21Z' fill='%23F5F4FA'/%3E%3Cpath opacity='0.08' d='M58 0C58 9.38884 65.6112 17 75 17L75 -2.02723e-07L58 0Z' fill='%23F5F4FA'/%3E%3Crect opacity='0.16' x='75' y='40' width='34' height='34' stroke='%23F0F0F5' stroke-width='6'/%3E%3Cellipse opacity='0.16' cx='48' cy='94' rx='10' ry='10' fill='%23D9D9D9'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        position: absolute;
        content: "";
        right: 0;
        bottom: 0px;
        width: 112px;
        height: 104px;
        background-size: contain;
        z-index: 0;
    }
    &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='127' height='196' viewBox='0 0 127 196' fill='none'%3E%3Cpath opacity='0.16' d='M111 64C111 47.9837 98.0163 35 82 35L82 64L111 64Z' fill='%23F0F0F5'/%3E%3Crect opacity='0.08' x='57' y='29' width='26' height='26' transform='rotate(-180 57 29)' stroke='%23F5F4FA' stroke-width='6'/%3E%3Crect opacity='0.08' x='127' y='80' width='16' height='16' transform='rotate(-180 127 80)' fill='%23F5F4FA'/%3E%3Cpath opacity='0.08' d='M50 106C50 127.539 67.4609 145 89 145L89 106L50 106Z' fill='%23F5F4FA'/%3E%3Cpath opacity='0.16' d='M0 56C27.6142 56 50 78.3858 50 106L-4.37114e-06 106L0 56Z' fill='%23F0F0F5'/%3E%3Cellipse opacity='0.08' cx='25' cy='126' rx='8' ry='8' transform='rotate(-180 25 126)' fill='%23F5F4FA'/%3E%3Cpath opacity='0.16' d='M53 183C53 177.477 57.4772 173 63 173C68.5228 173 73 177.477 73 183C73 188.523 68.5228 193 63 193C57.4772 193 53 188.523 53 183Z' stroke='%23F0F0F5' stroke-width='6'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 115px;
        height: 145px;
        background-size: cover;
    }
    .book-wrap {
        @include tablet {
            width: 100%;
            text-align: center;
        }
    }
}

.book-demo-section .link-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tablet {
        margin: auto;
    }

}

.book-demo-section .link-wrap a {
    color: var(--white);
}

.book-demo-section .link-wrap a:hover {
    color: var(--pink);
}

.book-demo-section {
    .btn {
        background-color: var(--pink);
        &:hover {
            background-color: var(--white);
            color: var(--pink);
        }
    }
}