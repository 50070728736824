@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap");
:root {
  --blue: #4f46e5;
  --white: #ffffff;
  --Grey: #4f4d66;
  --black: #05050d;
  --medium-black: #323147;
  --white-grey: #d2d1db;
  --pink: #db0061;
  --gold: #b79a3a;
  --lightblue: #05ABD6;
}
head + body {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
.flex {
  @include display-flex(flex);
}
.align-v-center {
  @include align-item(center);
}
.align-start {
  @include align-item(flex-start);
}
.align-end {
  @include align-item(flex-end);
}
.align-self-start {
  @include align-self(flex-start);
}
.justify-center {
  @include justify-content(center);
}
.justify-right {
  @include justify-content(flex-end);
}
.justify-space {
  @include justify-content(space-between);
}
.flex-wrap {
  @include flex-wrap(wrap);
}
.flex-direction {
  @include flex-direction(column);
}

.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.padding-none {
  padding: 0 !important;
}

.margin-none {
  margin: 0 !important;
}

.list-none {
  list-style-type: none;
  margin: 0;
}

select {
  appearance: auto;
  background: transparent;
  color: var(--white);
  width: auto;
  cursor: pointer;
  option {
    color: var(--black);
    background: transparent;
    margin: 16px;
    padding: 16px;
  }
}
ul li {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #e5e5eb;
  font-family: "DM Sans", sans-serif;
}
body {
  .container {
    max-width: 1380px;
    margin: auto;
    padding: 0;
    @include tablet {
        max-width: 100%;
        padding: 0 20px;
        .container {
            padding: 0;
        }
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    font-family: "DM Sans", sans-serif;
  }
  ul {
    li {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: var(--black);
    }
  }
  a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #e5e5eb;
    font-family: "DM Sans", sans-serif;
    text-decoration: none;
    // display: block;
    &:hover {
      color: var(--gold);
    }
  }
  .btn {
    font-size: 16px;
    line-height: 24px;
    background-color: var(--blue);
    color: var(--white);
    padding: 7px 23px;
    border-radius: 8px;
    &:hover {
      color: var(--white);
      background-color: var(--blue);
      opacity: 0.8;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-family: "Lexend", sans-serif;
  }
  h1 {
    font-size: 48px;
    line-height: 60px;
    color: var(--white);
    margin-bottom: 24px;
    @include mobile {
        font-size: 38px;
        line-height: 50px;
    }
    @include mobile-small {
        font-size: 30px;
        line-height: 40px;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
    color: var(--black);
    margin-bottom: 16px;
    text-align: center;
    @include mobile {
        font-size: 24px;
        line-height: 30px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    color: var(--black);
    margin-bottom: 16px;
    @include mobile {
        font-size: 22px;
        line-height: 25px;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 25px;
    color: var(--black);
    margin-bottom: 8px;
    @include mobile {
        font-size: 18px;
        line-height: 24px;
    }
    &:hover {
        a {
            color: var(--blue);
        }
    }
    a {
      font-size: 20px;
      line-height: 25px;
      color: var(--black);
      margin-bottom: 8px;
      font-family: "Lexend", sans-serif;
      @include mobile {
          font-size: 18px;
          line-height: 24px;
      }
    }
  }
}