.sl-stack--vertical.sl-stack--5 [id^="mosaic-provider-react-aria"] {
  margin-top: 16px !important;
}

.sl-pt-8 {
  padding-top: 12px;
}
.sl-bg-success,
.sl-bg-warning {
  color: var(--white);
}
.sl-mt-2 {
  margin-top: 12px;
}
label + select:hover {
  background-color: red !important;
}
.select-selected {
  background-color: red !important;
}
select:focus option:focus {
  background-color: red !important;
}
.sl-svg-focus {
  display: none !important;
}
.sl-menu--pointer-interactions .sl-menu-item:not(.sl-menu-item--disabled):hover,
.sl-menu-item--focused {
  background-color: var(--blue);
  color: var(--white) !important;
}
.sl-menu-item--focused .sl-truncate,
.sl-menu-item--focused svg {
  color: var(--white);
}
.sl-menu--pointer-interactions
  .sl-menu-item:not(.sl-menu-item--disabled):hover
  * {
  color: var(--white);
}
.sl-menu-item--submenu-active {
  background-color: var(--blue);
}

.sl-menu-item--submenu-active * {
  color: var(--white) !important;
}
[id^="mosaic-provider-react-aria"] .sl-bg-transparent > div {
  z-index: 9999;
}