.footer {
  background-color: var(--black);
  .wrap-box {
    @include display-flex(flex);
    @include justify-content(space-between);
    @include align-item(center);
    @include tablet {
      @include flex-direction(column);
      padding: 0 40px;
      text-align: center;
    }
    .sub-box-footer + .sub-box-footer {
      @include tablet {
        margin-top: 20px;
      }
    }
  }
}

.footer-first-box {
  padding: 100px 0;
  @include tablet {
    padding: 50px 0 20px;
  }
  @include mobile {
    padding: 30px 0 20px;
  }
}

.copyright-footer-box {
  padding: 34px 0;
  @include mobile {
    padding: 10px 0 30px;
  }
  .footer-links {
    @include display-flex(flex);
    @include justify-content(space-between);
    @include align-item(center);
    padding: 0;
    @include mobile {
      @include flex-direction(column);
      li {
        margin: 2px 0;
      }
    }
    &.social-links {
      @include flex-direction(row);
      li {
        margin: 0 8px;
        line-height: normal;
      }
    }
  }
  svg {
    transition: 0.3s ease-in-out;
    fill: var(--white);
  }
  a:hover svg path {
    fill: var(--gold);
  }
}

.footer-first-box .center-heading {
  text-align: left;
  margin-bottom: 16px;
  color: var(--white);
  @include tablet {
    text-align: center;
  }
}

.footer-first-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #9d9caf;
  margin: 0;
}
.footer-links li {
  margin: 0 8px;
  color: #6c6b80;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.footer-links li a {
  text-decoration: underline;
  color: #6c6b80;
  font-weight: 400;
  font-size: 14px;
  transition: 0.3s ease-in-out;
  line-height: 19px;
  display: inline-block;
  text-underline-offset: 3px;
  &:hover {
    color: var(--white);
    fill: var(--white);
  }
}
