.sl-icon {
  color: var(--blue);
}

.sl-truncate {
  // font-size: 14px;
  color: var(--black);

  span {
    color: var(--white);
  }
}

[rel="noopener noreferrer"] {
  display: none;
}

.dev {
  background-color: #f0f0f5;
  position: relative;

  ul,
  ol {
    padding: 0;
    font-family: "DM Sans", sans-serif;
  }

  hr {
    display: none;
  }

  @include tablet-small {
    background-color: var(--white);
  }

  .sl-overflow-y-auto.sl-flex-1.sl-w-full.sl-px-24.sl-bg-canvas {
    position: relative;

    overflow-y: auto;
    padding: 0 calc(50vw - 43rem) 2rem 3rem;
    position: relative;
    overflow-x: hidden;
    @include tablet {
      padding: 0 40px;
    }
    @include mobile {
      padding: 0 20px 0;
    }

    &:after {
      content: "";
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      background-color: var(--white);
      z-index: 9;
    }
    .sl-prose.sl-markdown-viewer,
    .sl-stack.sl-stack--horizontal {
      padding: 0 8px;

      // max-width: calc(100% - 240px);
      @include tablet-small {
        max-width: 100%;
      }

      [role="alert"] > div {
        padding: 16px;
      }
      + div {
        // padding-top: 34px;
        // @include tablet-small {
        //     padding: 34px 0 0;
        // }
        .sl-py-8 {
          padding-top: 0;
        }
      }

      > div {
        display: inline;

        div {
          display: inline;
          word-break: break-all;
        }
      }
    }

    > div {
      padding-top: 0;

      h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px;
        padding-top: 34px;
        @include mobile {
          font-size: 28px;
          line-height: 34px;
        }
        @include mobile-small {
          font-size: 26px;
          line-height: 30px;
        }
        a {
          font-size: 32px;
          line-height: 40px;
          font-weight: 700;
          @include mobile {
            font-size: 28px;
            line-height: 34px;
          }
          @include mobile-small {
            font-size: 26px;
            line-height: 30px;
          }
        }
      }

      h2 {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 15px;
        font-family: "Lexend", sans-serif;
        // margin: 0;
        @include mobile {
          font-size: 24px;
          line-height: 30px;
        }
        a {
          font-size: 24px;
          line-height: 28px;
          color: var(--black);
          margin-bottom: 0;
          font-weight: 700;
          font-family: "Lexend", sans-serif;
          @include mobile {
            font-size: 24px;
            line-height: 30px;
          }
        }

        &:hover a {
          color: var(--blue);
        }

        .sl-link-heading__icon {
          @include display-flex(flex);
        }
      }

      h3 {
        font-size: 22px;
        line-height: 30px;
        color: var(--black);

        a {
          font-size: 22px;
          line-height: 30px;
          font-weight: 700;
          font-family: "Lexend", sans-serif;
        }

        &:hover a {
          color: var(--blue);
        }
      }
      h4 {
        font-size: 20px;
        line-height: 25px;
        color: var(--black);
        margin: 0 0 15px;
        @include mobile {
          font-size: 18px;
          line-height: 24px;
        }
        a {
          font-size: 20px;
          line-height: 25px;
          font-weight: 700;
          font-family: "Lexend", sans-serif;
          margin-bottom: 0;
          @include mobile {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      h5 {
        font-size: 18px;
        line-height: 22px;
        color: var(--black);
        margin: 0 0 15px;
        a {
          font-size: 18px;
          line-height: 22px;
          font-weight: 700;
          font-family: "Lexend", sans-serif;
          @include mobile {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      h6 {
        font-size: 16px;
        line-height: 20px;
        color: var(--black);
        margin: 0 0 15px;
        a {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          font-family: "Lexend", sans-serif;
        }
      }
    }
  }

  > div {
    margin: auto;

    @include mobile {
      height: auto;
    }
  }

  .sl-elements-api {
    display: grid;
    grid-template-columns: calc(50vw - 90rem + 50rem) 250px 1fr;
    height: calc(100vh - 80px);

    @include desktop-small {
      padding: 0 0 0 60px;
      margin: auto;
    }

    @include tablet {
      padding: 0;
    }

    > .sl-flex {
      display: flex;
      grid-column: 2;
      justify-content: flex-end;
      height: calc(100vh - 80px);

      @include tablet-small {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
      }

      .sl-bg-canvas-100 {
        overflow-y: auto;
        background-color: #f0f0f5;
      }

      a > div {
        font-size: 14px;

        .sl-bg-primary-tint {
          background-color: #e0e7ff;
        }
      }

      a + div {
        font-size: 13px;
      }

      > div {
        height: calc(100vh - 80px);
        padding-top: 0;

        @include tablet-small {
          position: absolute;
          left: 0;
          z-index: 9;
          padding: 0;
          top: 0;
          height: 100%;

          .sl-overflow-y-auto {
            padding: 0;
          }
        }

        @include mobile {
          top: 0;
        }
      }

      > div + div {
        display: none;
      }
    }

    .sl-overflow-y-auto {
      @include tablet {
        padding: 0 0 0 30px;
      }
    }
  }
}

.sl-prose {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    font-family: "Lexend", sans-serif;
    text-align: left;
  }
  h1 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 24px;
    @include mobile {
      font-size: 38px;
      line-height: 50px;
    }
    @include mobile-small {
      font-size: 30px;
      line-height: 40px;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
    color: var(--black);
    margin: 0 0 15px;
    @include mobile {
      font-size: 24px;
      line-height: 30px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    color: var(--black);
    margin: 0 0 15px;
    @include mobile {
      font-size: 22px;
      line-height: 25px;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 25px;
    color: var(--black);
    margin: 0 0 15px;
    @include mobile {
      font-size: 18px;
      line-height: 24px;
    }
  }
  h5 {
    font-size: 18px;
    line-height: 22px;
    color: var(--black);
    margin: 0 0 15px;
  }
  h6 {
    font-size: 18px;
    line-height: 22px;
    color: var(--black);
    margin: 0 0 15px;
  }
  p {
    line-height: 24px;
    font-family: "DM Sans", sans-serif;
  }
}

.sl-font-sans,
.sl-font-ui {
  font-size: 14px !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d2d1db;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sl-bg-primary-tint {
  border-left: 2px solid var(--blue);
  background-color: #e0e7ff !important;

  &:hover {
    background-color: #e0e7ff !important;
  }
}

.ElementsTableOfContentsItem {
  div {
    &:hover {
      background: transparent;

      div {
        color: var(--blue);
      }
    }
  }
}

.sl-elements-article {
  .sl-markdown-viewer-toc {
    .sl-block {
      &:hover {
        color: var(--blue);
      }
    }

    .sl-border-primary {
      border-color: var(--blue);
    }
  }

  .sl-stack {
    h4 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
    }
  }

  .sl-sticky {
    top: 34px;
  }
}

.dev {
  + .footer {
    display: none;
  }
}

button[aria-haspopup="true"]:focus,
button.sl-border-primary {
  border-color: var(--white);
}

.sl-bg-transparent {
  // position: relative;
  z-index: 9999;

  [data-ismodal="true"] {
    #null-body > [aria-label="Search"] {
      height: 80vh;

      @include mobile {
        height: 70vh;
      }

      > div {
        &:hover {
          border-left: 2px solid var(--blue);
        }
      }
    }
  }
}

[data-testid="copy-button"] {
  svg {
    color: var(--white);
  }
}

.sl-bg-primary,
.sl-border-primary {
  border-color: var(--lightblue);
}

.toggle-menu {
  padding: 20px;
  position: relative;
  z-index: 9;
  display: none;

  @include tablet-small {
    padding: 30px 55px 0;
    @include display-flex(flex);
  }

  @include mobile {
    padding: 20px 20px 0;
  }

  svg {
    stroke: var(--black);
  }
}

.dev .sl-elements-api {
  @include tablet-small {
    @include display-flex(flex);
    height: auto;
    left: 0;
  }

  > .sl-flex {
    @include tablet-small {
      display: none;
    }

    + div {
      width: 100%;
      padding: 0;
    }
  }
}

.menu-opened {
  @include display-flex(flex !important);
  + div:before {
    content: "";
    display: block;
    width: calc(100% - 300px);
    height: 100vh;
    position: absolute;
    right: 0;
  }
}

.dev [data-testid="two-column-right"] {
  @include tablet-small {
    display: none;
  }

  button.sl-bg-canvas-100,
  .sl-bg-canvas-200 {
    background-color: #212a3b;
  }

  .sl-text-body {
    color: var(--white);
  }

  .sl-icon {
    color: var(--white);
  }

  .sl-text-muted {
    color: var(--white);

    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  [aria-label="load-example"] {
    color: var(--white);
    border: 1px solid var(--blue);
    border-radius: 8px;
    background-color: var(--blue);
    margin-bottom: 10px;

    &:hover {
      opacity: 0.9;
      background-color: var(--blue);
    }

    + span {
      font-size: 14px;
    }
  }

  .sl-bg-canvas-100 {
    p {
      color: var(--white);
    }
  }

  pre {
    background-color: #2d394e;

    * {
      color: var(--white);
    }

    .token.function,
    .token.punctuation,
    .token.property,
    .token.operator,
    .token.number,
    .token.boolean {
      color: var(--white) !important;
    }

    .token.string {
      color: var(--lightblue) !important;
    }
  }

  .sl-w-full {
    overflow: auto;
  }

  pre {
    margin-bottom: 0;
  }

  .sl-overflow-y-hidden {
    overflow: auto;
  }
}

[aria-label="Export"] {
  display: none;
}

.dev div:hover .sl-text-success {
  color: var(--color-success);
}

.dev pre {
  background-color: #000;

  &:focus {
    border: 0;
    box-shadow: none;
  }
}

.sl-prose caption a,
.sl-prose figcaption a,
.sl-prose li a,
.sl-prose p a,
.sl-prose table a {
  color: var(--blue);
}

.sl-stack--vertical.sl-stack--8 > :not(style) ~ :not(style) {
  margin-top: 16px;
}

.sl-stack--vertical.sl-stack--5 > :not(style) ~ :not(style) {
  margin-top: 10px;
}

.JsonSchemaViewer {
  > div {
    border-bottom: 0;
  }

  .sl-icon {
    color: inherit;
  }
}

.SendButtonHolder {
  > div > button {
    background-color: var(--blue);
    border-color: var(--blue);

    &:hover {
      background-color: var(--blue);
      border-color: var(--blue);
    }
  }
}

[role="presentation"] {
  > div {
    color: var(--black);
  }
}
