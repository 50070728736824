.header {
  background-color: var(--black);
  padding: 20px 0 0;
  position: relative;
  @include desktop-small {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar {
    @include display-flex(flex);
    @include justify-content(space-between);
    padding: 0;
    .logo {
      margin-bottom: 20px;
    }
  }
  .header-center {
    @include justify-content(center);
    @include align-item(center);
    @include mobile {
      display: none;
    }
    a {
      padding: 7px 0 29px;
      position: relative;
      &.help-center {
        margin-right: 48px;
        color: var(--white);
        &:hover {
          color: var(--gold);
        }
      }
    }
    .select {
      position: relative;
      &:hover {
        ul {
          opacity: 1;
          visibility: visible;
        }
        button {
          color: var(--gold);
          svg {
            stroke: var(--gold);
            transform: rotate(-180deg);
          }
        }
      }
      button {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: var(--white);
        padding: 7px 0 29px;
        font-family: "DM Sans", sans-serif;
        position: relative;
        transition: all 0.4s ease;
        svg {
          stroke: var(--white);
          display: inline-block;
          margin-left: 8px;
          transition: all 0.4s ease;
        }
        // &:hover {
        //   // color: var(--gold);
        //   svg {
        //     stroke: var(--gold);
        //     transform: rotate(-180deg);
        //   }
        // }
      }
      ul {
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
        background-color: var(--white);
        padding: 8px;
        position: absolute;
        top: 100%;
        min-width: 190px;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        z-index: 9;
        li {
          a {
            font-weight: 500;
            padding: 4px 8px;
            color: var(--black);
            &:hover {
              color: var(--gold);
            }
          }
          + li {
            margin-top: 8px;
          }
        }
      }
    }
  }
  .header-right {
    @include display-flex(flex);
    @include align-item(center);
    @include justify-content(center);
    padding-bottom: 20px;
    .contact {
      margin-right: 32px;
      @include mobile {
        display: none;
      }
    }
    .login {
      svg {
        display: none;
      }
      @include tablet-small {
        font-size: 0;
        line-height: normal;
        background: transparent;
        padding: 0;
        svg {
          @include display-flex(flex);
          stroke: var(--white);
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  #navbarScroll {
    margin-right: 32px;
    @include mobile {
      margin-right: 10px;
    }
  }
}
#mosaic-provider-react-aria-0-2 {
  z-index: 9999;
}

.menu-button-mobile {
  display: none;
  margin: 0 10px;
  &.menu-button-mobile {
    svg {
      width: 20px;
      height: 20px;
      stroke: var(--white);
    }
  }
  @include mobile {
    display: block;
  }
  svg {
    fill: var(--white);
  }
}
.mobile-menu {
  position: absolute;
  top: 100%;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  z-index: 9999;
  padding: 20px 0;
  ul {
    margin: 10px 0;
    li {
      margin: 10px 0;
      &.open {
        svg {
          transform: rotate(-180deg);
        }
      }
      ul {
        padding-left: 10px;
      }
      a {
        color: var(--black);
      }
    }
  }
  .select {
    button {
      @include display-flex(flex);
      @include align-item(center);
      svg {
        margin-left: 10px;
        stroke: var(--black);
        fill: var(--white);
        transition: all 0.4s ease;
      }
    }
  }
}
.sl-bg-transparent {
  #react-aria-0-439 {
    height: 80vh;
    > div {
      > div {
        &:hover {
          border-left: 2px solid var(--blue);
        }
      }
    }
  }
}

#navbarScroll {
  button {
    svg {
      stroke: var(--white);
    }
    &:hover {
      svg {
        stroke: var(--gold);
      }
    }
  }
}
